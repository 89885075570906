<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU05.000') }}</h1><!-- 운임 -->
    <e-tab ref="tab">
      <!-- 운임문의 -->
      <e-tab-item
        :title="$t('menu.MENU05.010')"
        name="inquiry"
        path="/shipping-cost"
      >
        <shipping-cost-inquiry v-if="routeName === '' || routeName === 'inquiry'"></shipping-cost-inquiry>
      </e-tab-item>
      <!-- 나의 운임 -->
      <e-tab-item
        :title="$t('menu.MENU05.020')"
        name="my"
        path="/shipping-cost"
      >
        <shipping-cost-my v-if="routeName === 'my'"></shipping-cost-my>
      </e-tab-item>
      <!-- FAK 운임 -->
      <e-tab-item
        :title="$t('menu.MENU05.030')"
        name="fak"
        path="/shipping-cost"
        v-if="memberDetail.userCtrCd === 'KR' && fakOpnYn === 'Y'"
      >
        <shipping-cost-fak v-if="routeName === 'fak'"></shipping-cost-fak>
      </e-tab-item>
    </e-tab>
  </div>
</template>

<script>

import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'
import { rootComputed } from '@/store/helpers'

import ShippingCostFak from '@/pages/schedule/ShippingCostFak'
import ShippingCostInquiry from '@/pages/schedule/ShippingCostInquiry'
import ShippingCostMy from '@/pages/schedule/ShippingCostMy'
import scheduleApi from '../../api/rest/schedule/schedule'

export default {
  name: 'ShippingCostMain',
  components: {
    EBreadcrumbs,
    ETabItem,
    ETab,
    ShippingCostInquiry,
    ShippingCostMy,
    ShippingCostFak
  },
  data () {
    return {
      routeName: '',
      fakOpnYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    $route (to, from) {
      console.log(to.name)
      this.routeName = to.name
      this.$refs.tab.selectTab({ name: this.$route.name })
    }
  },
  created () {
    this.routeName = this.$route.name || ''

    if (this.memberDetail.userCtrCd === 'KR') {
      this.getfakOpnYn()
    }
  },
  mounted () {
    this.$refs.tab.selectTab({ name: this.$route.name })
  },
  methods: {
    //FAK 운임 탭 노출여부 조회
    getfakOpnYn () {
      scheduleApi.getFakOpnYn().then((res) => {
        if (res.data) {
          this.fakOpnYn = res.data
        }
      })
    }
  }
}

</script>
